import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImageWithOverlappedCard from "../../../../Components/ImageWithOverlappedCard";
import { getReviewOffersAction } from "../../../../store/actions/offers";

export default function UnderReview() {
  const { t } = useTranslation(["global"]);

  const dispatch = useDispatch();

  const offersList = useSelector((s) => s?.offers.reviewOffers?.data);
  const loading = useSelector((s) => s?.offers.reviewOffers?.loading);
  const hasMore = useSelector((s) => s?.offers.reviewOffers?.hasMore);

  const getOffers = useCallback(
    ({ next = false }) => {
      getReviewOffersAction({ limit: 6, next, status: "pending" })(dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    getOffers && getOffers({ page: 1 });
  }, [getOffers]);

  const handleSeeMore = () => {
    getOffers({ next: true });
  };

  return (
    <Box id="UnderReview">
      <ImageWithOverlappedCard
        data={offersList}
        loading={loading}
        hasTitle={false}
        hasButton={false}
        emptyTitle={t("no_requests_title")}
        emptySubtitle={t("no_requests_subtitle")}
        linkUrl={"request"}
        renderId={(item) => item?.request?.id}
        renderName={(item) => item?.request?.project?.name}
      />

      {hasMore && offersList.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "2em" }}>
          <Button
            variant="text"
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleSeeMore}
          >
            <Typography variant="body1" sx={{ color: "#615F5F" }}>
              {t("see_more")}
            </Typography>
            <KeyboardArrowDown sx={{ color: "#615F5F" }} />
          </Button>
        </Box>
      )}
    </Box>
  );
}
