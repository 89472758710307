import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { IoSettingsOutline } from "react-icons/io5";
import { IoReceiptOutline } from "react-icons/io5";
import { BsEnvelopePaper } from "react-icons/bs";
import { SlHome } from "react-icons/sl";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { useSelector } from "react-redux";

export default function MinAppBar() {
  const navigate = useNavigate();

  const { t } = useTranslation(["global"]);
  const userData = useSelector((state) => state?.auth?.user);

  const tabs = [
    { name: "homeWord", value: "/", icon: <SlHome />, setBottom: false },
    {
      name: "projects",
      value: "/projects",
      icon: <BsEnvelopePaper />,
      setBottom: false,
    },
    {
      name: "bills",
      value: "/bills",
      icon: <IoReceiptOutline />,
      setBottom: false,
    },

    {
      name: "settings",
      value: "/settings",
      icon: <IoSettingsOutline />,
      setBottom: false,
    },
  ];

  const supplierTabs = [
    { name: "home", value: "/", icon: <SlHome /> },
    {
      name: "bills",
      value: "/bills",
      icon: <IoReceiptOutline />,
      setBottom: false,
    },
    { name: "settings", value: "/settings", icon: <IoSettingsOutline /> },
  ];

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        width: 1,
      }}
    >
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          bgcolor: "background.paper",
          position: "relative",
          borderTop: "1px solid gray",
          height: "90px",
        }}
      >
        {userData?.accountType === "user" && (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              position: "absolute",
              top: "-20px",
              width: 1,
            }}
            onClick={() => navigate(`/add-request`)}
          >
            <IconButton
              sx={{
                bgcolor: "#D6630F",
                color: "white",
                "&:hover": {
                  bgcolor: "#D6630F",
                  color: "white",
                },
              }}
            >
              {" "}
              <AddIcon />
            </IconButton>
          </Box>
        )}

        <Grid spacing={1} container sx={{ height: 1, alignItems: "center" }}>
          {userData?.accountType === "user" ? (
            <>
              {tabs?.map((item, index) => (
                <Grid item xs={3} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // pt: 2,
                      color: "gray",
                      "&:hover": {
                        color: "#D6630F",
                      },
                    }}
                    onClick={() => navigate(`${item?.value}`)}
                  >
                    <Typography variant="h6" sx={{ py: 1 }}>
                      {" "}
                      {item?.icon}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      {t(item?.name)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {supplierTabs?.map((item, index) => (
                <Grid item xs={2} key={index} sx={{ width: 1, mx: "auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      color: "gray",
                      "&:hover": {
                        color: "#D6630F",
                      },
                    }}
                    onClick={() => navigate(`${item?.value}`)}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "800" }}>
                      {" "}
                      {item?.icon}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      {t(item?.name)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
