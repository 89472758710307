import {
  Box,
  Button,
  Divider,
  Menu,
  Tab,
  Tabs,
  Typography,
  Avatar,
  IconButton,
  Badge,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { footerBg, logo } from "../assets/index";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  ExpandMore,
  SystemUpdateAlt,
  Logout,
  Language,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DropDownMenu from "../Components/DropDownMenu/index.jsx";
import { logoutAction } from "../store/actions/auth/index.js";
import { SlHome } from "react-icons/sl";
export default function Navbar() {
  const { t, i18n } = useTranslation(["global"]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTop, setIsTop] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElM, setAnchorElM] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const path = location.pathname;
  const openM = Boolean(anchorElM);

  const tabs = [
    { name: "home", value: "/", icon: <SlHome /> },
    {
      name: "projects",
      value: "/projects",
    },
    {
      name: "bills",
      value: "/bills",
    },
    {
      name: "add_request",
      value: "/add-request",
    },
    {
      name: "settings",
      value: "/settings",
    },
  ];

  const supplierTabs = [
    { name: "home", value: "/" },
    {
      name: "bills",
      value: "/bills",
    },
    { name: "settings", value: "/settings" },
  ];

  const changeLanguage = useCallback(() => {
    let lang = i18n.language;
    let newLang = "en";
    if (lang === "en") {
      newLang = "ar";
    }

    localStorage.setItem("lng", newLang);
    window?.location?.reload();
  }, [i18n]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorElM(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElM(null);
  };

  const userData = useSelector((state) => state?.auth?.user);
  const totalUnRead = useSelector((s) => s.notifications.totalUnRead);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    handleClose();
  };

  const handleNavigate = (link) => {
    navigate(link);
    handleClose();
  };

  const profileDropDown = [
    {
      icon: <SystemUpdateAlt sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: t("update_profile"),
      function: () => handleNavigate("/settings?tab=editProfile"),
    },
    // {
    //   icon: <ChangeCircle sx={{ transition: "color 0.5s ease-in-out" }} />,
    //   text: t("Change Information"),
    // },
    {
      icon: (
        <Logout sx={{ transition: "color 0.5s ease-in-out", color: "red" }} />
      ),
      text: t("logout"),
      function: handleLogout,
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${footerBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          maxWidth: "100vw",
          display: { sm: "flex", xs: "none" },
          justifyContent: "space-between",
          alignItems: "center",
          p: "0.5em 2em",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9,
          boxShadow: !isTop && "0px 10px 20px rgba(117, 117, 117, 0.18)",
        }}
      >
        <Box component={Link} to={"/"}>
          <img
            src={logo}
            alt=""
            style={{ height: "100%", cursor: "pointer" }}
          />
        </Box>

        {/*PC View*/}
        <Tabs
          value={path}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            display: { xs: "none", sm: "flex" },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "primary.main",
            },
            "& .MuiTab-root": {
              color: "background.paper",
              transition: "color 0.3s ease-in-out",
              "&:hover": {
                color: "primary.main",
              },
            },
            "& .MuiTabs-scrollButtons": {
              color: "background.paper",
            },
          }}
        >
          {userData?.accountType === "user"
            ? tabs?.map((item, index) => (
                <Tab
                  key={index}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => navigate(`${item?.value}`)}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "400" }}>
                        {t(item?.name)}
                      </Typography>
                    </Box>
                  }
                  value={item?.value}
                />
              ))
            : supplierTabs?.map((item, index) => (
                <Tab
                  key={index}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => navigate(`${item?.value}`)}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "400" }}>
                        {t(item?.name)}
                      </Typography>
                    </Box>
                  }
                  value={item?.value}
                />
              ))}
        </Tabs>

        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2em",
              "& Button, & a": {
                color: "background.paper",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: "transparent",
                },
                transition:
                  "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
              },
            }}
          >
            <Button
              variant="text"
              onClick={changeLanguage}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                "& > *": {
                  fill: "#FFF",
                },
              }}
            >
              <Language />
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "400",
                  color: "#FFF",
                }}
              >
                {i18n.language}
              </Typography>
            </Button>
          </Box>
          <Button
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleClick}
          >
            <Avatar src={userData?.image} />
            <Typography variant="body1" sx={{ color: "#FFF" }}>
              {userData?.username}
            </Typography>
            <ExpandMore
              sx={{
                transform: `rotate(${openMenu ? "180deg" : 0})`,
                transition: "0.3s ease-in-out",
                color: "#FFF",
              }}
            />
          </Button>

          <IconButton
            size="small"
            onClick={() => navigate("/settings?tab=notification")}
          >
            <Badge badgeContent={totalUnRead} color="secondary">
              <NotificationsIcon sx={{ color: "#FFF" }} />
            </Badge>
          </IconButton>

          <DropDownMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            data={profileDropDown}
          />
        </Box>
        {/*PC View*/}

        {/*Mobile View*/}
        <Button
          onClick={handleOpenMenu}
          size="small"
          sx={{
            height: "fit-content",
            width: "fit-content",
            p: "0.5em",
            minWidth: "fit-content",
            display: { xs: "inline-flex", sm: "none" },
          }}
          aria-controls={openM ? "header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openM ? "true" : undefined}
        >
          <MenuIcon sx={{ color: "primary.main" }} />
        </Button>

        <Menu
          disableScrollLock={true}
          anchorEl={anchorElM}
          open={openM}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              p: "0.5em",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Tabs
            orientation="vertical"
            value={path}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "primary.main",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "primary.main",
              },
              "& .MuiTab-root": {
                color: "#002222",
                transition: "color 0.3s ease-in-out",
                "&:hover": {
                  color: "primary.main",
                },
              },
              "& .MuiTabs-scrollButtons": {
                color: "background.paper",
              },
            }}
          >
            {userData?.accountType === "user"
              ? tabs?.map((e, i) => (
                  <Tab
                    key={i}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {t(e?.name)}
                        </Typography>
                      </Box>
                    }
                    value={e?.value}
                    aria-haspopup={e?.name === "industries"}
                  />
                ))
              : supplierTabs?.map((e, i) => (
                  <Tab
                    key={i}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {t(e?.name)}
                        </Typography>
                      </Box>
                    }
                    value={e?.value}
                    aria-haspopup={e?.name === "industries"}
                  />
                ))}
          </Tabs>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "1em",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1em",
                "& Button, & a": {
                  color: "#002222",
                  "&:hover": {
                    color: "primary.main",
                    bgcolor: "transparent",
                  },
                  transition:
                    "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                },
                p: "1em 1em 0 1em",
              }}
            >
              <Button
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "start",
                  gap: "1em",
                  width: "100%",
                }}
                onClick={changeLanguage}
              >
                <Language />
                <Typography
                  variant="body1"
                  sx={{ textTransform: "uppercase", fontWeight: "400" }}
                >
                  {i18n.language}
                </Typography>
              </Button>
              <Button
                sx={{
                  p: 0,
                  display: "flex",
                  justifyContent: "start",
                  gap: "1em",
                  "& > *": {
                    color: "red",
                  },
                  width: "100%",
                }}
                onClick={handleLogout}
              >
                <Logout />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  {t("logout")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Menu>
        {/*Mobile View*/}
      </Box>
      <Box
        sx={{
          display: { sm: "none", xs: "flex" },
          justifyContent: "space-around",
          alignItems: "center",
          width: 1,
          py: 2,
          bgcolor: "#eeeeee",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box component={Link} to={"/"} sx={{ width: "50px" }}>
            <img
              src={logo}
              alt=""
              style={{
                height: "100%",
                cursor: "pointer",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Button
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleClick}
          >
            <Avatar src={userData?.image} />
            <Typography variant="body1" sx={{ color: "#615F5F" }}>
              <Typography variant="span" color={"black"}>
                {t("welcome")} ,
              </Typography>{" "}
              {userData?.username}
            </Typography>
            <ExpandMore
              sx={{
                transform: `rotate(${openMenu ? "180deg" : 0})`,
                transition: "0.3s ease-in-out",
                color: "#FFF",
              }}
            />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            onClick={() => navigate("/settings?tab=notification")}
          >
            <Badge badgeContent={totalUnRead} color="secondary">
              <NotificationsIcon sx={{ color: "gray" }} />
            </Badge>
          </IconButton>
          <Button
            sx={{
              p: 0,
              display: "flex",
              justifyContent: "start",
              gap: "1em",
            }}
            onClick={changeLanguage}
          >
            <Language />
            <Typography
              variant="body1"
              sx={{ textTransform: "uppercase", fontWeight: "400" }}
            >
              {i18n.language}
            </Typography>
          </Button>
        </Box>

        {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box component={Link} to={"/"} sx={{ width: "50px" }}>
            <img
              src={logo}
              alt=""
              style={{
                height: "100%",
                cursor: "pointer",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box>
            <Button
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
              }}
              onClick={handleClick}
            >
              <Avatar src={userData?.image} />
              <Typography variant="body1" sx={{ color: "#615F5F" }}>
                <Typography variant="span" color={"black"}>
                  {t("welcome")} ,
                </Typography>{" "}
                {userData?.username}
              </Typography>
              <ExpandMore
                sx={{
                  transform: `rotate(${openMenu ? "180deg" : 0})`,
                  transition: "0.3s ease-in-out",
                  color: "#FFF",
                }}
              />
            </Button>
          </Box>
        </Box> */}
        {/* <Box>
          <Button
            sx={{
              p: 0,
              display: "flex",
              justifyContent: "start",
              gap: "1em",
              width: "100%",
            }}
            onClick={changeLanguage}
          >
            <Language />
            <Typography
              variant="body1"
              sx={{ textTransform: "uppercase", fontWeight: "400" }}
            >
              {i18n.language}
            </Typography>
          </Button>
          <IconButton
            size="small"
            onClick={() => navigate("/settings?tab=notification")}
          >
            <Badge badgeContent={totalUnRead} color="secondary">
              <NotificationsIcon sx={{ color: "gray" }} />
            </Badge>
          </IconButton>
        </Box> */}
      </Box>
    </>
  );
}
